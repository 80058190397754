@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tenor+Sans&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #F0EADC;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }

  .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
  }
}

.modal {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 rounded-lg shadow-lg;
  width: 90%;
  max-width: 400px;
}

.overlay {
  @apply fixed top-0 left-0 right-0 bottom-0  bg-opacity-75 backdrop-blur-md z-50;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5px);
  }
}

.arrow-animate {
  transition: transform 0.3s ease-in-out;
}

.arrow-icon {
  transition: transform 1s ease-in-out;
}

button:hover .arrow-icon {
  transform: translateX(10px);
}

button:not(:hover) .arrow-icon {
  transform: translateX(0);
}

@keyframes moveForward {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}

@keyframes moveBackward {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.arrow-auto-animate {
  /* Initial state (no animation) */
  transition: transform 0.3s ease; /* Optional: smooth transition when hovering */
}

.arrow-auto-animate:hover {
  animation: moveForward 10s ease-in-out infinite alternate, moveBackward 0.8s ease-in-out infinite alternate;
}

@keyframes borderBottomFadeIn {
  from {
    border-bottom-width: 0;
  }
  to {
    border-bottom-width: 2px;
  }
}

.header-with-border {
  animation: borderBottomFadeIn 0.3s forwards;
  border-bottom: 2px solid #754F23;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_card {
  z-index: 999;
}



@keyframes pulse {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.4;
  }
  100% {
      opacity: 1;
  }
}
/* Ensure smooth transitions for max-height */
.accordion-content {
  overflow: hidden;
  max-height: 0; /* Start with closed state */
}

.accordion-content.open {
  max-height: 500px; /* Adjust this value based on your content height */
}

/* Additional styling for rotating the icon */
.transition-transform {
  transition: transform 0.3s ease-in-out;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

.skeleton {
  background: #f6f7f8;
  background-image: linear-gradient(
    90deg,
    #f6f7f8 0px,
    #edeef1 40px,
    #f6f7f8 80px
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

.skeleton-text {
  height: 20px;
  margin-bottom: 10px;
}

.skeleton-image {
  height: 300px;
  width: 100%;
  margin-bottom: 10px;
}

.skeleton-button {
  height: 40px;
  width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@layer components {
  .no-spinner {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    opacity: 1;
    -webkit-appearance: "Always Show Up/Down Arrows";
  }
}

@layer components {
  .spinner {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .spinner::-webkit-outer-spin-button,
  .spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}